#contact {
  display: grid;
}

.contact-form-container {
  width: 100%;
  max-width: 500px;
  margin: auto;
  padding: 1rem;
}

.contact-title {
  max-width: 500px;
  color: var(--title-color);
  font-weight: bold;
  font-size: var(--font-large);
  text-align: left;
}

.contact-input, .contact-message, .contact-button {
  display: block;
  width: 100%;
  margin-top: 2rem;
  padding: 1rem;
  border-radius: 10px;
  font-size: 1.25rem;
  font-family: Poppins;
  background-color: #f1f5f9;
}

.contact-input {
  border: none;
}

.contact-input:focus {
  outline: none;
}

.contact-message {
  min-height: 200px;
  resize: none;
  border: none;
}

.contact-message:focus {
  outline: none;
}

.contact-button {
  font-size: 1.2rem;
  background-color: rgb(188, 253, 253);
  border: none;
  transition: transform 500ms;
}

.contact-button:hover {
  box-shadow: 0px 5px 10px #999;
  cursor: pointer;
  transform: scale(1.03);
}

.contact-button:active {
  transform: scale(.97);
}