.theme-container {
  font-size: 1.5rem;
  padding: 0.5rem 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  transition: all 0.3s ease-in;
}

.theme-container:hover {
  cursor: pointer;
}
