.home-main {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.home-title-name {
  color: var(--body-color);
  font-weight: bold;
  font-size: 45px;
}

.home-title-role {
  color: var(--body-color);
  font-weight: bold;
  font-size: 25px;
}

.home-desc {
  color: var(--text-color);
  margin-top: 1rem;
  text-align: center;
  width: 80%;
}

.home-name {
  font-style: italic;
  font-weight: bold;
}

.home-links {
  display: flex;
  margin-top: 1.5rem;
}

.home-icon {
  color: var(--body-color);
  font-size: 25px;
  margin: 0 2rem;
  transition: transform 250ms;
}

.home-icon:hover {
  transform: translateY(-5px);
}

.home-img {
  display: block;
  width: 90%;
  max-width: 400px;
  height: 400px;
  margin: auto;
  object-fit: cover;
  border-radius: 100%;
}
