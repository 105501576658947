.project-wrapper {
  display: grid;
  align-items: center;
  text-align: center;
}

.portfolio-img {
  width: 90%;
  max-width: 550px;
  height: 450px;
  object-fit: cover;
  border-radius: 5%;
  margin: 1.5rem auto;
}

.portfolio-title {
  font-weight: bold;
  font-size: var(--font-large);
}

.portfolio-desc {
  margin: 1.5rem auto;
  color: var(--text-color);
  padding: 1.5rem;
  max-width: 500px;
}

.portfolio-framework {
  margin: auto 1rem;
  font-weight: bold;
}

.portfolio-link-wrapper {
  display: flex;
  margin: auto;
  width: 50%;
}

.portfolio-link-wrapper a {
  text-decoration: none;
  color: var(--body-color);
  font-weight: bold;
  margin: 2rem auto;
}

.portfolio-link {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 250ms ease-in;
  transition: transform 250ms ease-in;
}

.portfolio-link:hover {
  transform: translateY(-5px);
  color: var(--text-color);
}

.portfolio-github-icon {
  font-size: 1.5rem;
  margin-left: 10px;
}

@media (min-width: 1100px) {
  .project-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 600px);
    gap: 2rem;
    align-items: center;
    justify-content: center;
  }
}