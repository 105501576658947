.about-wrapper {
  display: flex;
  flex-direction: column;
  gap: 7rem;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
}

.about-img {
  width: 95%;
  max-width: 500px;
  border-radius: 25px;
}

.about-text {
  max-width: 600px;
  text-align: left;
  padding: 1rem;
}

.about-title {
  font-weight: bold;
  margin-top: 10px;
}

.about-desc {
  width: 100%;
  margin-top: 15px;
  color: var(--text-color);
}

@media (min-width: 1200px) {
  .about-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 550px);
    gap: 1rem;
    align-items: center;
    justify-content: center;
    padding: 5rem 1rem;
  }
}
