section {
  scroll-margin-top: 100px;
  min-height: 100vh;
  font-size: var(--font-medium);
  padding: 1rem 0rem;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 5%;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: var(--nav-background);
  color: var(--nav-color);
  z-index: 100;
}

.nav-title {
  font-size: 30px;
  font-weight: bold;
}

#nav-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

#nav-links li {
  list-style: none;
  margin: 0 5px;
}

.nav-link {
  display: block;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  color: inherit;
  padding: 0.75rem 1.5rem;
  border-radius: 10px;
  transition: 0.3s ease-in-out;
}

.nav-link:hover:not(.nav-link-active) {
  background-color: var(--nav-hover-background);
  color: var(--nav-hover-color);
}

.nav-link-active {
  color: var(--nav-active-color);
}

.mobile {
  display: none;
}

#mobile-icon {
  color: var(--body-color);
  align-items: center;
}

@media screen and (max-width: 800px) {
  .nav {
    padding: 0.75rem 5%;
  }

  #nav-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    top: 70px;
    right: -770px;
    width: 100%;
    background-color: var(--body-background);
    color: var(--body-color);
    border-radius: 3%;
    transition: 0.5s ease-in-out;
  }

  #nav-links.active {
    right: 0;
  }

  #nav-links li {
    width: 100%;
    text-align: center;
    margin: 0;
  }

  .nav-link {
    width: 100%;
    margin: none;
    padding: none;
  }

  .nav-link:hover:not(.nav-link-active) {
    color: var(--nav-hover-color);
    background-color: var(--nav-hover-background);
  }

  .mobile {
    display: block;
  }

  #mobile-icon {
    font-size: 25px;
    cursor: pointer;
  }
}
